import React, { useState } from 'react';
import { Typography, Tabs, Tab } from '@mui/material';
import SlipCard from './SlipCard';
import TopMatches from './TopMatches';

const SideSection = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    const tabContentStyle = {
      height: '25vh',
      background: '#121212',
      color: '#DBDBDB',
      padding: '10px',
      border: '3px solid #D7D7D7',
      overflow: 'auto', 
      marginTop: '0.5em',
      borderRadius: '5px'
    };
  
    if (selectedTab === 0) {
      return (
        <Typography variant="body1" style={tabContentStyle}>
         <SlipCard/>
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" style={tabContentStyle}>
          All slips
        </Typography>
      );
    }
  };
  

  return (
    <div style={{
      position: 'fixed',
      top: '5em',
      right: '1em',
      zIndex: 1000,
      padding: '10px', 
      width: '25%'
    }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Column 1 */}
        <div style={{ flex: 1 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Prediction Tabs"
          >
            <Tab label="Slip" sx={{ color: '#F2F1F2' }}/>
            <Tab label="My Picks" sx={{ color: '#F2F1F2' }}/>
          </Tabs>
          {renderTabContent()}
        </div>
      </div>

      {/* Column 2 */}
      <TopMatches/>
    </div>
  );
};

export default SideSection;
