// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-nav {
    position: relative;
  }
  
  .selector {
    position: absolute;
    width: 30px; 
    height: 30px;
    border-radius: 50%;
    background-color: orangered;
    transition: all 0.3s ease;
  }
  
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-link.active + .selector {
    transform: translateX(calc(50% - 15px)); 
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/profileNav.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,uCAAuC;EACzC","sourcesContent":[".profile-nav {\n    position: relative;\n  }\n  \n  .selector {\n    position: absolute;\n    width: 30px; \n    height: 30px;\n    border-radius: 50%;\n    background-color: orangered;\n    transition: all 0.3s ease;\n  }\n  \n  .nav-link {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .nav-link.active + .selector {\n    transform: translateX(calc(50% - 15px)); \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
