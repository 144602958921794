import React from 'react'
import Navigation from '../Components/Navigation';
import Profile from '../Components/MvpProfile'



export default function UserProfile() {
  return (
    <div style={{backgroundColor: "#040404"}}> 
        <Navigation/>
        <Profile />
    </div>
  )
}
