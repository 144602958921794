import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.css'; 

export default function Navigation() {
  const [openNavCentred, setOpenNavCentred] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const location = useLocation();

  useEffect(() => {
    // Extract the path name from the location object
    const pathname = location.pathname;
    // Update the active link based on the current path
    setActiveLink(pathname.substring(1)); // Remove the leading '/'
  }, [location]);

  return (
    <Navbar fixed='top' bg='dark' expand='lg' variant='dark' className='navbar-custom'>
      <Container fluid>
        <Navbar.Brand href='#' className='navbar-brand-custom'>Colosseum</Navbar.Brand>
        <Navbar.Toggle
          aria-controls='navbarCenteredExample'
          aria-label='Toggle navigation'
          onClick={() => setOpenNavCentred(!openNavCentred)}
        >
          <FaBars />
        </Navbar.Toggle>

        <Navbar.Collapse id='navbarCenteredExample'>
          <Nav className='mb-2 mb-lg-0 ms-auto'>
            <Link to='/home' className={`nav-link ${activeLink === 'home' ? 'active' : ''}`}>
              Home
            </Link>
            <Link to='/league-standings' className={`nav-link ${activeLink === 'league-standings' ? 'active' : ''}`}>
              Leagues
            </Link>
            <NavDropdown title='Forums & Community' id='basic-nav-dropdown' className={`nav-link ${activeLink === 'forum' || 'create-forum' ? 'active' : ''}`}>
              <Link to='/forum' className='dropdown-item'>Global Forums</Link>
              <Link to='/create-forum' className='dropdown-item'>Create a Forum</Link>
              <Link to='#something-else' className='dropdown-item'>Something else here</Link>
            </NavDropdown>
            <Link to='#' className='nav-link'>Link</Link>
            <Link to='#' className='nav-link'>Logout</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
