// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.dashboard{
    display: flex;
    gap: 20px;
    background: #dde6ed;
    padding: 1em;

}
.dashboard-content{
    background: #fff;
    flex: 1 1;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between ;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,YAAY;;AAEhB;AACA;IACI,gBAAgB;IAChB,SAAO;IACP,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,+BAA+B;AACnC","sourcesContent":["body{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n.dashboard{\n    display: flex;\n    gap: 20px;\n    background: #dde6ed;\n    padding: 1em;\n\n}\n.dashboard-content{\n    background: #fff;\n    flex: 1;\n    border-radius: 20px;\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1.5rem;\n    justify-content: space-between ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
