import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { RiHome3Line } from 'react-icons/ri';
import { RiGroupLine } from 'react-icons/ri'; 
import { RiNotification3Line } from 'react-icons/ri';
import { RiChat3Line } from 'react-icons/ri';
import { RiUser3Line } from 'react-icons/ri';
import './profileNav.css'; 

const ProfileNav = () => {
  const [activeTab, setActiveTab] = useState('home');

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <Navbar expand="lg" style={{ border: 'none', borderRadius: '25px', width: "300px", backgroundColor: "#dde6ed" }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-center align-items-center">
        <Nav
          className="profile-nav"
          activeKey={activeTab}
          onSelect={handleSelect}
        >
          <Nav.Link href="/home" eventKey="home">
            <RiHome3Line size={25} color="#FF6934" />
          </Nav.Link>
          <Nav.Link href="/forum" eventKey="group">
            <RiGroupLine size={25} color="#FF6934" />
          </Nav.Link>
          <Nav.Link href="/notifications" eventKey="notification">
            <RiNotification3Line size={25} color="#FF6934" />
          </Nav.Link>
          <Nav.Link href="/chat" eventKey="chat">
            <RiChat3Line size={25} color="#FF6934" />
          </Nav.Link>
          <Nav.Link href="/user-profile" eventKey="profile">
            <RiUser3Line size={25} color="#FF6934" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ProfileNav;
