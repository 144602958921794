import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

export default function SlipCard({ slipId, numBets }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button 
      onClick={handleClickOpen('paper')} 
      style={{ color: '#DBDBDB',
       background: '#121212',
        border: '3px solid #D7D7D7',
         marginBottom: '10px',
         width: '100%'
          }}>
            {`Slip ID: ${slipId} (${numBets})`} 
            </Button>
            <Dialog
  open={open}
  onClose={handleClose}
  scroll={scroll}
  aria-labelledby="scroll-dialog-title"
  aria-describedby="scroll-dialog-description"
  PaperComponent={CustomPaper}
>
  <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>{`Slip ID: ${slipId}`}</DialogTitle>
  <DialogContent dividers={scroll === 'paper'} style={{ width: '30vw' }}>
    <DialogContentText
      id="scroll-dialog-description"
      ref={descriptionElementRef}
      tabIndex={-1}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <p>Dummy Data</p>
          {/* Your dummy data */}
        </div>
        <div>
          <p>Odds</p>
          {/* Your odds data */}
        </div>
      </div>
    </DialogContentText>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center' }}>
    <Button onClick={handleClose} style={{ color: '#DBDBDB', background: '#121212', border: '3px solid #D7D7D7', marginRight: '10px' }}>Cancel</Button>
    <Button onClick={handleClose} style={{ color: '#DBDBDB', background: '#121212', border: '3px solid #D7D7D7' }}>Submit</Button>
  </DialogActions>
</Dialog>

    </>
  );
}

function CustomPaper(props) {
  return <Paper {...props} style={{ background: '#2E2E2E', border: '3px solid #D7D7D7', color: '#DBDBDB' }} />;
}
