import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { RiQuestionFill } from 'react-icons/ri';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import './TopMatches.css';

const TopMatches = () => {
  // Dummy data for top matches
  const topMatches = [
    {
      league: 'France - Ligue 1',
      match: 'PSG vs Lyon',
      popularity: 465844,
    },
    {
      league: 'Spain - LaLiga',
      match: 'Real Madrid vs Barcelona',
      popularity: 459053,
    },
    {
      league: 'Portugal - Liga Portugal',
      match: 'Sporting vs Guimaraes',
      popularity: 428664,
    },
    {
      league: 'Italy - Serie A',
      match: 'Monza vs Atalanta',
      popularity: 398880,
    },
    {
      league: 'Portugal - Liga Portugal',
      match: 'Casa Pia Lisbon vs FC Porto',
      popularity: 336053,
    },
    {
      league: 'France - Ligue 1',
      match: 'Toulouse vs Marseille',
      popularity: 305826,
    },
    {
      league: 'Spain - LaLiga',
      match: 'CD Alaves vs Atletico Madrid',
      popularity: 275962,
    },
    {
      league: 'Germany - Bundesliga',
      match: 'Freiburg vs Mainz',
      popularity: 267923,
    },
  ];

  // Sort matches by popularity
  const sortedMatches = topMatches.sort((a, b) => b.popularity - a.popularity);

  return (
    <div className='page' style={{ marginTop: '20px' }}>
      <Typography variant="h5" style={{ marginBottom: '10px', color: '#F2F1F2', textAlign: 'center' }}>Top 10 Hottest Matches in the Past Hour</Typography>
      <Typography variant="subtitle1" style={{ marginBottom: '10px', color: '#F2F1F2', textAlign: 'center' }}>Refreshed every 10 minutes</Typography>
      <Carousel interval={5000} indicators={false} prevIcon={<div />} nextIcon={<div />}>
        {sortedMatches.map((matchup, index) => (
          <Carousel.Item key={index}>
            <div className='carousel-content'>
              <Card style={{ background: '#2E2E2E', border: '3px solid #D7D7D7', color: '#DBDBDB', marginBottom: '10px', width: '300px', height: '120px' }}>
                <CardContent style={{ padding: '0.5em'}}>
                  <Typography variant="body2">{matchup.league}</Typography>
                  <Typography variant="body2">{matchup.match}</Typography>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Typography variant="body2" style={{ color: '#F2F1F2' }}>Popularity: {matchup.popularity}</Typography>
                    <Tooltip title="Ranking based on the number of selections made by real-time users on a specific match. The higher the ranking, the higher the attention the match is receiving">
                      <IconButton>
                        <RiQuestionFill style={{ color: '#F2F1F2', cursor: 'pointer', marginLeft: '5px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography variant="body2" style={{ color: '#F2F1F2', position: 'absolute', bottom: '10px', right: '10px', padding: '1.5em' }}>Rank: {index + 1}</Typography>
                </CardContent>
              </Card>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default TopMatches;
