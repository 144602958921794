// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Gets rid of scroll bar but keeps functionality in check*/
:root::-webkit-scrollbar{
  display: none;
}

:root {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,0DAA0D;AAC1D;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":["/*Gets rid of scroll bar but keeps functionality in check*/\n:root::-webkit-scrollbar{\n  display: none;\n}\n\n:root {\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
