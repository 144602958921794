import React, { useState } from 'react';
import { AiOutlineBell, AiOutlineMessage, AiOutlineUser, AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useNavigate } from 'react-router-dom';

export default function SpeedDialButton() {
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState(<AiOutlinePlus/>);
    const navigate = useNavigate();

    const handleToggle = () => {
        setOpen(!open);
        setIcon(open ? <AiOutlinePlus/> : <AiOutlineClose/>);
    };

    const handleNavigation = (path) => {
        setOpen(false);
        navigate(path);
    };


    return (
        <div style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: '999' }}>
            <SpeedDial
                ariaLabel="SpeedDial"
                direction="up"
                icon={icon}
                onClick={handleToggle}
                open={open}
            >
                <SpeedDialAction icon={<AiOutlineBell />} tooltipTitle="Notification" onClick={() => console.log("Notification clicked")} />
                <SpeedDialAction icon={<AiOutlineMessage />} tooltipTitle="Chat" onClick={() => console.log("Chat clicked")} />
                <SpeedDialAction icon={<AiOutlineUser />} tooltipTitle="Profile" onClick={() => handleNavigation("/user-profile")} />
            </SpeedDial>
        </div>
    );
}
