import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login Page/Login';
import ProfilePage from './Pages/Dashboard/Dashboard';
import Homepage from './Pages/Home';
import Forum from './Pages/Forums'
import CreateForum from './Pages/CreateForums'
import LeagueStandings from './Pages/LeagueStandings';
import UserProfile from './Pages/UserProfile';
import './App.css'

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/forum" element={<Forum />} />
          <Route path="/create-forum" element={<CreateForum />} />
          <Route path="/league-standings" element={<LeagueStandings />} />
          <Route path="/user-profile" element={<UserProfile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
