import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab, Button, Form, Row, Col, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Success from '../../Assets/login-success.gif';
import "./Login.css"

function Login() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('login');
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  });
  const [registerFormData, setRegisterFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false
  });
  const [error, setError] = useState({ type: '', message: '' });
  const [showSignupSuccessModal, setShowSignupSuccessModal] = useState(false);
  const [isLoginSuccessModalShown, setIsLoginSuccessModalShown] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e, formType) => {
    const { name, value, checked } = e.target;
    if (formType === 'login') {
      setLoginFormData({ ...loginFormData, [name]: value });
    } else if (formType === 'register') {
      setRegisterFormData({ ...registerFormData, [name]: name === 'agreeToTerms' ? checked : value });
    }
  };

  const handleShowSignupSuccessModal = () => {
    setShowSignupSuccessModal(true);
    
    setTimeout(() => {
      setShowSignupSuccessModal(false);
      setActiveTab('login');
    }, 1500);
  };

  const resetError = () => {
    setTimeout(() => {
      setError({ type: '', message: '' });
    }, 2000);
  };

  const handleRegister = async () => {
    const { firstName, lastName, email, password, confirmPassword, agreeToTerms } = registerFormData;

    if (!firstName || !lastName || !email || !password || !confirmPassword || !agreeToTerms) {
      setError({ type: 'register', message: 'Please fill in all the fields.' });
      resetError();
      return;
    }

    if (password !== confirmPassword) {
      setError({ type: 'register', message: 'Passwords do not match.' });
      resetError();
      return;
    }

    try {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        role: 'user'
      };

      const response = await axios.post('https://colosseum-backend.tiacloud.dev/register', formData);
      console.log(response.data);
      
      handleShowSignupSuccessModal();
    } catch (error) {
      console.error('Error registering user:', error);
      if (error.response.status === 409) {
        setError({ type: 'register', message: 'Email already exists.' });
      } else {
        setError({ type: 'register', message: 'Failed to register. Please try again.' });
      }
      resetError();
    }
  };

  const handleLogin = async () => {
    const { email, password } = loginFormData;

    if (!email || !password) {
      setError({ type: 'login', message: 'Please fill in all the fields.' });
      resetError();
      return;
    }

    try {
      const formData = {
        email,
        password
      };

      const response = await axios.post('https://colosseum-backend.tiacloud.dev/login', formData);
      console.log(response.data);
      setIsLoginSuccessModalShown(true);
    } catch (error) {
      console.error('Error logging in:', error.message);
      if (error.response.status === 401) {
        setError({ type: 'login', message: 'Invalid credentials.' });
      } else {
        setError({ type: 'login', message: 'Failed to login. Please check your credentials and try again.' });
      }
      resetError();
    }
  };

  useEffect(() => {
    if (isLoginSuccessModalShown) {
      setTimeout(() => {
        setIsLoginSuccessModalShown(false);
        navigate('/profile');
      }, 1500);
    }
  }, [isLoginSuccessModalShown, navigate]);

  const registerTab = () => {
    setActiveTab('register');
  };

  return (
    <Container className="p-3 my-5 d-flex flex-column w-50 custom-container" style={{backgroundColor: '#040404', color: '#fff'}}>
      <h3 className='text-center' style={{marginBottom: '1em'}}>Colosseum</h3>
      {error.type && error.type === 'register' && <Alert variant="danger">{error.message}</Alert>}
      <Tabs 
        justify 
        className='mb-3 d-flex flex-row justify-content-between' 
        activeKey={activeTab} 
        onSelect={(tab) => handleTabChange(tab)} 
        style={{marginBottom: '1em'}}
      >
        <Tab eventKey="login" title="Login">
          <Form className="mt-3">
            <Form.Group controlId="formBasicEmail" style={{marginTop: '2em'}}>
              <Form.Control type="email" placeholder="Enter email" name="email" value={loginFormData.email} onChange={(e) => handleInputChange(e, 'login')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" style={{marginTop: '2em'}}>
              <Form.Control type="password" placeholder="Password" name="password" value={loginFormData.password} onChange={(e) => handleInputChange(e, 'login')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
            </Form.Group>
            <div className="d-flex justify-content-between mx-4 mb-4" style={{marginTop: '2em'}}>
              <Form.Check type="checkbox" label="Remember me" style={{color: '#fff'}} />
              <a href="!#" style={{color: '#95A1F9'}}>Forgot password?</a>
            </div>
            {error.type && error.type === 'login' && <Alert variant="danger">{error.message}</Alert>}
            <Button variant="primary" className="mb-4 w-100" onClick={handleLogin}>Sign in</Button>
            <p className="text-center">Not a member? <a href="#!" onClick={registerTab} style={{color: '#95A1F9'}}>Register</a></p>
          </Form>
        </Tab>
        <Tab eventKey="register" title="Register" style={{marginBottom: '1em'}}>
          <Form className="mt-3">
            <Row className="mb-4">
              <Col>
                <Form.Control  type = "text" placeholder="First Name"  name="firstName" value={registerFormData.firstName} onChange={(e) => handleInputChange(e, 'register')} required style={{ marginRight: '2em', backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
              </Col>
              <Col>
                <Form.Control  type = "text" placeholder="Last Name" name="lastName" value={registerFormData.lastName} onChange={(e) => handleInputChange(e, 'register')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
              </Col>
            </Row>
            <Form.Group controlId="formBasicEmail" style={{marginTop: '2em'}}>
              <Form.Control type="email" placeholder="Email" name="email" value={registerFormData.email} onChange={(e) => handleInputChange(e, 'register')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" style={{marginTop: '2em'}}>
              <Form.Control type="password" placeholder="Password" name="password" value={registerFormData.password} onChange={(e) => handleInputChange(e, 'register')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
            </Form.Group>
            <Form.Group controlId="formBasicConfirmPassword" style={{marginTop: '2em'}}>
              <Form.Control type="password" placeholder="Confirm Password" name="confirmPassword" value={registerFormData.confirmPassword} onChange={(e) => handleInputChange(e, 'register')} required style={{backgroundColor: '#040404', color: '#fff', borderColor: '#95A1F9'}} />
            </Form.Group>
            <div className='d-flex justify-content-center mb-4' style={{marginTop: '2em'}}>
              <Form.Check type="checkbox" label="I have read and agree to the terms" name="agreeToTerms" checked={registerFormData.agreeToTerms} onChange={(e) => handleInputChange(e, 'register')} required style={{color: '#fff'}} />
            </div>
            {error.type && error.type === 'register' && <Alert variant="danger">{error.message}</Alert>}
            <Button variant="primary" className="mb-4 w-100" onClick={handleRegister}>Sign up</Button>
          </Form>
        </Tab>
      </Tabs>
      <Modal show={showSignupSuccessModal} onHide={() => setShowSignupSuccessModal(false)} centered>
        <Modal.Body className="text-center">
          <img src={Success} alt="Success" />
        </Modal.Body>
      </Modal>
      {isLoginSuccessModalShown && (
        <Modal show={true} onHide={() => setIsLoginSuccessModalShown(false)} centered>
          <Modal.Body className="text-center">
            <img src={Success} alt="Success" />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default Login;
