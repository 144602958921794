import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Avatar from '@mui/material/Avatar';
import { BiStats } from 'react-icons/bi';
import { AiOutlineLineChart } from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

function MatchupCarousel() {
    // Dummy data for matches
    const dummyMatches = [
        {
          football: 'Football',
          country: 'England',
          league: 'Premier League',
          team1: 'Manchester United',
          team2: 'Liverpool',
          team1Badge: 'https://via.placeholder.com/80', // Example image URL for badge
          team2Badge: 'https://via.placeholder.com/80',
        },
        {
          football: 'Football',
          country: 'Spain',
          league: 'La Liga',
          team1: 'Barcelona',
          team2: 'Real Madrid',
          team1Badge: 'https://via.placeholder.com/80', // Example image URL for badge
          team2Badge: 'https://via.placeholder.com/80',
        },
        {
          football: 'Football',
          country: 'Italy',
          league: 'Serie A',
          team1: 'Juventus',
          team2: 'Inter Milan',
          team1Badge: 'https://via.placeholder.com/80', // Example image URL for badge
          team2Badge: 'https://via.placeholder.com/80',
        },
        {
          football: 'Football',
          country: 'Germany',
          league: 'Bundesliga',
          team1: 'Bayern Munich',
          team2: 'Borussia Dortmund',
          team1Badge: 'https://via.placeholder.com/80', // Example image URL for badge
          team2Badge: 'https://via.placeholder.com/80',
        },
        {
          football: 'Football',
          country: 'France',
          league: 'Ligue 1',
          team1: 'Paris Saint-Germain',
          team2: 'Olympique de Marseille',
          team1Badge: 'https://via.placeholder.com/80', // Example image URL for badge
          team2Badge: 'https://via.placeholder.com/80',
        },
      ];

  return (
    <Carousel style={{ marginTop: '1em', width: '100%' }}>
      {dummyMatches.map((match, index) => (
        <Carousel.Item key={index} interval={5000}>
          <a href="#nono" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <img
                className="d-block w-100"
                src={`https://source.unsplash.com/800x400/?${match.team1},${match.team2}`}
                alt={`Slide ${index + 1}`}
              />
              <div className="carousel-content" 
              style={{ display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              width: '100%', 
              height: '100%', 
              color: 'white', 
              backgroundColor: '#222222AD', 
              backdropFilter: 'blur(5px)', 
              transition: 'backdrop-filter 0.3s ease-in-out' }}>
                <div>
                  <h5>{match.football}</h5>
                  <p>{match.country} - {match.league}</p>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar src={match.team1Badge} alt={match.team1} style={{ marginRight: '10px', width: 'auto', height: 'auto' }} />
                      <span style={{ fontSize: '24px' }}>{match.team1}</span>
                    </div>
                    <div style={{ fontSize: '48px', margin: '10px' }}>VS</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontSize: '24px' }}>{match.team2}</span>
                      <Avatar src={match.team2Badge} alt={match.team2} style={{ marginLeft: '10px', width: 'auto', height: 'auto' }} />
                    </div>
                  </div>
                  <p>{/* Dummy data for date and time */}</p>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', gap: '1em' }}>
                    <Tooltip title="Statistics" arrow>
                    <IconButton>
                      <BiStats size={36} style={{ color: 'white' }}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Odds Movement Chart" arrow>
                        <IconButton>
                      <AiOutlineLineChart size={36} style={{ color: 'white' }}/>
                       </IconButton>
                    </Tooltip>
                  </div>
                  {/* Placeholder for line chart (to be implemented) */}
                </div>
              </div>
            </div>
          </a>
          <Carousel.Caption>
            <p className='text-align-center'>Placeholder caption</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MatchupCarousel;
