import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, InputBase, Tabs, Tab, Container } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import GamePredictionCard from '../Components/PredictionCard';
import TeamMatchupCarousel from '../Components/TeamMatchupCarousel'
const MatchesAppBar = () => {
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { value: 'one', label: 'Item One' },
    { value: 'two', label: 'Item Two' },
    { value: 'three', label: 'Item Three' },
    { value: '4', label: 'Item 4' },
    { value: '5', label: 'Item 5' },
    { value: '6', label: 'Item 6' },
    { value: '7', label: 'Item 7' },
    { value: '8', label: 'Item 8' },
    { value: '9', label: 'Item 9' },
  ];



  return (
    <Container fluid style={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: '#4B0082' }}>
          <Toolbar>
            <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
              Live Games
            </Typography>
            <div style={{
              position: 'relative',
              borderRadius: '4px',
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              width: '30%'
            }}>
              <div style={{
                padding: '8px',
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <FaSearch />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                style={{
                  color: 'inherit',
                  width: '100%',
                  padding: '8px 8px 8px 40px',
                  transition: 'width 300ms',
                  borderRadius: '4px'
                }}
              />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        {tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} sx={{ color: '#F2F1F2' }} />
        ))}
      </Tabs>
      <GamePredictionCard />
      <TeamMatchupCarousel/>
    </Container>
  );
}

export default MatchesAppBar;

