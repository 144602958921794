// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-custom {
    background-color: #040404;
  }
  
  .navbar-brand-custom {
    color: #95A1F9; 
  }
  
  .nav-link {
    color: #F2F1F2 
  }
  
  .nav-link.active {
    color: #95A1F9 
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Navigation.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE;EACF;;EAEA;IACE;EACF","sourcesContent":[".navbar-custom {\n    background-color: #040404;\n  }\n  \n  .navbar-brand-custom {\n    color: #95A1F9; \n  }\n  \n  .nav-link {\n    color: #F2F1F2 \n  }\n  \n  .nav-link.active {\n    color: #95A1F9 \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
