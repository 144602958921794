import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Navigation from '../Components/Navigation';
import League from '../Components/League';
import SpeedDialButton from '../Components/SpeedDial';
import BackButton from '../Components/BackButton';


const LeagueStandings = () => {
  return (
    <div style={{ width: '100%%', height: '100vh', overflow: 'hidden', backgroundColor: "#040404" }}>
      <Navigation />
      <BackButton/>
      <Container fluid style={{ width: '95%', height: '90%', marginTop: '5%',display: 'flex', flexDirection: 'column', justifyContent: 'center'  }}>
        <Row style={{ height: '100%' }}>
          <Col md={12} style={{ padding: '5px'}}>
               <League />
          </Col>
        </Row>
        <SpeedDialButton />
      </Container>
    </div>
  );
};

export default LeagueStandings;
