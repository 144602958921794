import React from 'react';
import Navigation from '../Components/Navigation';
import CreateForum from '../Components/CreateForum';
import SpeedDial from '../Components/SpeedDial';
import BackButton from '../Components/BackButton';

export default function CreateForums() {
  return (
    <div style={{backgroundColor: '#040404'}}>
        <Navigation/>
        <BackButton/>
        <CreateForum/>
        <SpeedDial/>
    </div>
  )
}
