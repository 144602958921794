import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup, Badge } from 'react-bootstrap';
import { Tabs, Tab, Typography } from '@mui/material';
import { FaEnvelope, FaUserPlus } from 'react-icons/fa';
import BackButton from './BackButton';

const dummyData = {
  profile: {
    name: "John Wells",
    title: "MVP Gold",
    bio: "Hi I'm John. I've been the industry's standard dummy text To an English person alteration text.",
    email: "[email protected]",
    achievements: ["Golden Analyst", "Master Forecaster", "Elite Predictor", "Peerless Pundit"],
    communities: [
      { name: "Football Fans", avatar: "https://via.placeholder.com/50", joined: false },
      { name: "Basketball", avatar: "https://via.placeholder.com/50", joined: false },
      { name: "Tennis Lovers", avatar: "https://via.placeholder.com/50", joined: false }
    ]
  },
  tasks: [
    { title: "Predict the Outcome of Manchester United vs Liverpool match", status: "Completed" },
    { title: "Provide Analysis for the NBA Finals", status: "In Progress" },
    { title: "Write a Review of the Latest Tennis Grand Slam", status: "Completed" }
  ]
};

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState('tasks');
  const [communityTab, setCommunityTab] = useState('posts');
  const [profileData, setProfileData] = useState(dummyData.profile); // Initialize with dummy data
  const [userDataLoading, setUserDataLoading] = useState(false); // Set to true if fetching user data

  // Fetch profile data based on username or user ID
  useEffect(() => {
    // Uncomment and add Axios code here to fetch profile data
    // const fetchProfileData = async () => {
    //   try {
    //     const response = await axios.get(`/api/profile/${usernameOrUserID}`);
    //     setProfileData(response.data);
    //     setUserDataLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching profile data:', error);
    //   }
    // };

    // fetchProfileData();
  }, []); // Trigger only on component mount

  // Function to handle follow button click
  const handleFollow = () => {
    console.log('Follow successful');
    // Add logic for actual follow functionality
    // Example Axios post request:
    // axios.post('/api/follow', { userId: 'user_id_to_follow' })
    //   .then(response => {
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error following user:', error);
    //   });
  };

  // Function to handle joining a community
  const handleJoinCommunity = (communityName) => {
    console.log(`Joined community: ${communityName}`);
    // Add logic for joining the community
    // Example Axios post request:
    // axios.post('/api/joinCommunity', { communityName: communityName })
    //   .then(response => {
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error joining community:', error);
    //   });
  };

  // Function to handle tab change in the main tab section
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Function to handle tab change in the community tab section
  const handleCommunityTabChange = (event, newValue) => {
    setCommunityTab(newValue);
  };

  // Render tasks list
  const renderTasks = () => {
    return (
      <ListGroup>
        {dummyData.tasks.map((task, index) => (
          <ListGroup.Item key={index}  style={{  marginBottom: "1em",
           backgroundColor: '#251F2D',
            color: '#AD7DEA',
             border: '2px solid rgba(173,125,234,1)',
              backdropFilter: 'blur(3px)',
              display: "flex",
              flexDirection: "row",
              alignItems: "center"

               }}>
            <Row>
              <Col xs={10}>{task.title}</Col>
              <Col xs={2}><Badge bg={task.status === "Completed" ? "success" : (task.status === "Pending" ? "warning" : "primary")}>{task.status}</Badge></Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  // Render communities list
  const renderCommunities = () => {
    return (
      <>
        {profileData.communities.map((community, index) => (
          <Card key={index} className="mb-3"  style={{ backgroundColor: '#251F2D',
           color: '#AD7DEA',
            border: '2px solid rgba(173,125,234,1)',
            backdropFilter: 'blur(3px)',
             }}>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={2} style={{padding: '0'}}>
                  <img src={community.avatar} alt={community.name} className="img-fluid rounded-circle" />
                </Col>
                <Col xs={7}>
                  <Card.Title>{community.name}</Card.Title>
                </Col>
                <Col xs={3}>
                  {!community.joined ? (
                    <Badge bg="primary" 
                    className="follow-badge d-flex align-items-center justify-content-center text-align-center" 
                    onClick={() => handleJoinCommunity(community.name)} 
                    style={{ cursor: 'pointer', width: '60px', height: '35px', fontSize: '13px' }}>
                      <FaUserPlus className="me-1" /> Join
                    </Badge>
                  ) : (
                    <Badge bg="success" 
                    style={{ width: '70px', height: '35px', fontSize: '13px' }}
                    >Joined</Badge>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </>
    );
  };

  return (
    <Container style={{backgroundColor: '#040404', color: '#F2F1F2', paddingTop: '5em'}}>
      <BackButton />
      <Row className="mt-4">
        <Col md={8}>
          <Card className="mb-4" style={{ backgroundColor: '#03131A', color: '#F2F1F2' }}>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={3}>
                  <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt={profileData.name} className="img-fluid rounded-circle" />
                </Col>
                <Col xs={9}>
                  <Card.Title>{profileData.name}</Card.Title>
                  <Card.Subtitle className="mb-2 " style={{color: "goldenrod"}}>{profileData.title}</Card.Subtitle>
                  <Card.Text>{profileData.bio}</Card.Text>
                </Col>
              </Row>
              <Card.Text>
                <FaEnvelope /> {profileData.email}
              </Card.Text>
              <Badge bg="primary" className="follow-badge" onClick={handleFollow} style={{ cursor: 'pointer' }}>
                <FaUserPlus className="me-1" /> Follow
              </Badge>
            </Card.Body>
          </Card>
          <Card style={{ backgroundColor: '#03131A', color: '#F2F1F2' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="tasks" label="Tasks" sx={{ color: '#F2F1F2' }}/>
              <Tab value="activity" label="Activity" sx={{ color: '#F2F1F2' }}/>
              <Tab value="standings" label="Standings" sx={{ color: '#F2F1F2' }}/>
            </Tabs>
            <Card.Body>
              {activeTab === 'tasks' && renderTasks()}
              {activeTab === 'activity' && <Typography variant="body1">Activity Tab Content</Typography>}
              {activeTab === 'standings' && <Typography variant="body1">Standings Tab Content</Typography>}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4" style={{ backgroundColor: '#03131A', color: '#F2F1F2' }}>
            <Card.Body>
              <Tabs
                value={communityTab}
                onChange={handleCommunityTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="posts" label="Posts" sx={{ color: '#F2F1F2' }} />
                <Tab value="replies" label="Replies" sx={{ color: '#F2F1F2' }}/>
                <Tab value="predictions" label="Predictions" sx={{ color: '#F2F1F2' }}/>
              </Tabs>
              {communityTab === 'posts' && <Typography variant="body1">Posts Tab Content</Typography>}
              {communityTab === 'replies' && <Typography variant="body1">Replies Tab Content</Typography>}
              {communityTab === 'predictions' && <Typography variant="body1">Predictions Tab Content</Typography>}
            </Card.Body>
          </Card>
          <Card className="mb-4" style={{ backgroundColor: '#03131A', color: '#F2F1F2' }}>
            <Card.Body>
              <Card.Title>Achievements</Card.Title>
              {profileData.achievements.map((skill, index) => (
                <Badge key={index} bg="secondary" className="me-2 mb-2">{skill}</Badge>
              ))}
            </Card.Body>
          </Card>
          <Card className="mb-4" style={{ backgroundColor: '#03131A', color: '#F2F1F2' }}>
            <Card.Body>
              <Card.Title>Communities</Card.Title>
              {renderCommunities()}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
