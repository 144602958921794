import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../../Components/Sidebar/Sidebar';
import './Dashboard.css';
import ProfileNav from '../../Components/profileNav';

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <Sidebar />
      <div className='dashboard-content'>
        <Row style={{ height: '20%' }}>
          {/* First Row */}
          <Col>
            {/* Content for the first row */}
            <div style={{ background: '#fff', borderRadius: '20px', padding: '1rem', display: "flex", flexDirection: "row-reverse" }}>
              <ProfileNav/>
            </div>
          </Col>
        </Row>
        <Row style={{ height: '80%' }}>
          {/* Second Row */}
          <Col xs={8} style={{ paddingRight: '0.75rem' }}>
            {/* First Column - 70% */}
            <div style={{ background: '#fff', borderRadius: '20px', padding: '1rem' }}>
              {/* Content */}
              Column 1 - 70% Width
            </div>
          </Col>
          <Col xs={4} style={{ paddingLeft: '0.75rem' }}>
            {/* Second Column - 30% */}
            <div style={{ background: '#fff', borderRadius: '20px', padding: '1rem' }}>
              {/* Content */}
              Column 2 - 30% Width
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
