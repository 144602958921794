// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu{
    display: flex;
    flex-direction: column;
    gap: 2.3 rem;
    height: 94.5vh;
}
.logo{
    text-align: center;
    padding: 20px;
    color: #27374d;
    align-items: center;
    display: flex;
    gap: 20px;
}
.logo-icon{
    font-size: 2rem;
}
.icon{
    font-size: 1.8rem;
}
.menu-list{
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.menu-list .item{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    color: #27374d;
    margin-bottom: 0.5em;
}
.menu-list .item:hover{
    background: #27374d;
    color: #dde6ed;

}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;AACA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;AAEb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,gCAAgC;IAChC,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB,cAAc;;AAElB","sourcesContent":[".menu{\n    display: flex;\n    flex-direction: column;\n    gap: 2.3 rem;\n    height: 94.5vh;\n}\n.logo{\n    text-align: center;\n    padding: 20px;\n    color: #27374d;\n    align-items: center;\n    display: flex;\n    gap: 20px;\n}\n.logo-icon{\n    font-size: 2rem;\n}\n.icon{\n    font-size: 1.8rem;\n}\n.menu-list{\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n}\n.menu-list .item{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    font-size: 1.1rem;\n    text-decoration: none;\n    font-weight: 600;\n    padding: 10px;\n    border-radius: 10px;\n    transition: all 0.3s ease-in-out;\n    color: #27374d;\n    margin-bottom: 0.5em;\n}\n.menu-list .item:hover{\n    background: #27374d;\n    color: #dde6ed;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
