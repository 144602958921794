import React from 'react';
import Navigation from '../Components/Navigation';
import { Container, Row, Col } from 'react-bootstrap';
import ColumnOne from '../Components/MatchesPrediction';
import ColumnTwo from '../Components/SideSection'



const Home = () => {
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: "#040404" }}>
        <Navigation/>
        <Container fluid style={{ paddingTop: "5em"}}>
      <Row>
        <Col md={9}>
          <ColumnOne />
        </Col>
        <Col md={3}>
          <ColumnTwo />
        </Col>
      </Row>
    </Container>
    </div>
  )
}


export default Home;