import React from 'react';
import { Nav } from 'react-bootstrap';
import { FaChartLine, FaHistory, FaMoneyBillWave, FaCog } from 'react-icons/fa';
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className='menu'>
      <div className='logo'>
        <div className="d-flex justify-content-center align-items-center rounded-circle bg-secondary" style={{ width: '150px', height: '150px', border: '5px solid white' }}>
          <h2 className="text-light">TU</h2>
        </div>
      </div>

      <div className='menu-list'>
        <Nav className="flex-column mt-3">
          <Nav.Link href="#" className='item'>
            <FaChartLine className='me-2 icon' />
            <span>Predict</span>
          </Nav.Link>
          <Nav.Link href="#" className='item'>
            <TbDeviceDesktopAnalytics className='me-2 icon' />
            <span>Analysis</span>
          </Nav.Link>
          <Nav.Link href="#" className='item'>
            <FaHistory className='me-2 icon' />
            <span>History</span>
          </Nav.Link>
          <Nav.Link href="#" className='item'>
            <FaMoneyBillWave className='me-2 icon' />
            <span>Earnings</span>
          </Nav.Link>
          <Nav.Link href="#" className='item'>
            <FaCog className='me-2 icon' />
            <span>Settings</span>
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
