import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import { FaArrowLeft } from 'react-icons/fa';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div style={{ position: 'fixed', top: 90, left: 20, zIndex: 999 }}>
      <Fab onClick={handleBack} color="primary" aria-label="back" size="medium">
        <FaArrowLeft size={24} />
      </Fab>
    </div>
  );
};

export default BackButton;
