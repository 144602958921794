import React from 'react'
import Navigation from '../Components/Navigation'
import GlobalForum from '../Components/GlobalForum'
import SpeedDialButton from '../Components/SpeedDial'
import BackButton from '../Components/BackButton'

export default function Forums() {
  return (
    <div style={{backgroundColor: '#040404'}}>
        <Navigation/>
        <BackButton/>
        <GlobalForum/>
        <SpeedDialButton/>
    </div>
  )
}
