// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container {
    background-color: #040404;
    color: #fff;
    border-radius: 20px;
  }
  
  .custom-container input[type="text"],
  .custom-container input[type="email"],
  .custom-container input[type="password"],
  .custom-container textarea,
  .custom-container select {
    background-color: #ffffff;
    color: white;
  }
  
  .custom-container input[type="text"]::placeholder,
  .custom-container input[type="email"]::placeholder,
  .custom-container input[type="password"]::placeholder,
  .custom-container textarea::placeholder,
  .custom-container select::placeholder {
    color: white;
  }
  
  .custom-container .nav-link.active {
    background-color: #6a0dad !important;
    color: white !important;
    border-radius: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Login Page/Login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;EACrB;;EAEA;;;;;IAKE,yBAAyB;IACzB,YAAY;EACd;;EAEA;;;;;IAKE,YAAY;EACd;;EAEA;IACE,oCAAoC;IACpC,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":[".custom-container {\n    background-color: #040404;\n    color: #fff;\n    border-radius: 20px;\n  }\n  \n  .custom-container input[type=\"text\"],\n  .custom-container input[type=\"email\"],\n  .custom-container input[type=\"password\"],\n  .custom-container textarea,\n  .custom-container select {\n    background-color: #ffffff;\n    color: white;\n  }\n  \n  .custom-container input[type=\"text\"]::placeholder,\n  .custom-container input[type=\"email\"]::placeholder,\n  .custom-container input[type=\"password\"]::placeholder,\n  .custom-container textarea::placeholder,\n  .custom-container select::placeholder {\n    color: white;\n  }\n  \n  .custom-container .nav-link.active {\n    background-color: #6a0dad !important;\n    color: white !important;\n    border-radius: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
