import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Pagination from '@mui/material/Pagination';

const GamePredictionCard = () => {
  // State to store user selections for each game
  const [gamePredictions, setGamePredictions] = useState({});

  // Function to handle user selection for a specific game
  const handlePrediction = (gameIndex, prediction) => {
    setGamePredictions(prevState => ({
      ...prevState,
      [gameIndex]: prediction,
    }));
  };

  // Dummy data for game details
  const dummyGames = [
    {
      team1: 'Team A',
      team2: 'Team B',
      date: '2024-04-25',
      time: '15:00',
      location: 'Stadium XYZ',
    },
    {
      team1: 'Team C',
      team2: 'Team D',
      date: '2024-04-26',
      time: '18:00',
      location: 'Stadium ABC',
    },
    {
      team1: 'Team E',
      team2: 'Team F',
      date: '2024-04-27',
      time: '14:00',
      location: 'Stadium DEF',
    },
    {
      team1: 'Team G',
      team2: 'Team H',
      date: '2024-04-28',
      time: '16:00',
      location: 'Stadium GHI',
    },
    {
      team1: 'Team I',
      team2: 'Team J',
      date: '2024-04-29',
      time: '17:00',
      location: 'Stadium JKL',
    },
    // Add more dummy game details as needed
  ];

  // Define outcome options
  const outcomeOptions = [
    { id: 'homeWin', label: 'Home Win', symbol: '1', odds: '2.00' },
    { id: 'draw', label: 'Draw', symbol: 'X', odds: '3.50' },
    { id: 'awayWin', label: 'Away Win', symbol: '2', odds: '3.00' },
    { id: 'over2_5Goals', label: 'Over 2.5 Goals', symbol: 'O2.5', odds: '1.80' },
    { id: 'under2_5Goals', label: 'Under 2.5 Goals', symbol: 'U2.5', odds: '2.20' },
    { id: 'bothTeamsToScore', label: 'Both Teams to Score', symbol: 'BTTS', odds: '2.10' },
    // Add more options as needed
  ];

   // Handler function for pagination click
   const handlePaginationClick = (event, page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log('Page clicked:', page);
  };
  

  return (
    <div style={{ paddingTop: '1em', textAlign: 'center' }}>
      {dummyGames.map((game, index) => (
        <div key={index}>
          <Card style={{ background: '#2E2E2E', border: '3px solid #D7D7D7', color: '#DBDBDB' }}>
            <Card.Body>
              <Row>
                <Col xs={12} md={3}>
                  <h5>{game.team1} vs {game.team2}</h5>
                  <p>{game.date} |{game.time}</p>
                </Col>
                <Col xs={12} md={9}>
                  <ToggleButtonGroup
                    value={gamePredictions[index]}
                    exclusive
                    onChange={(event, newPrediction) => handlePrediction(index, newPrediction)}
                    aria-label="text alignment"
                  >
                    {outcomeOptions.map(option => (
                      <ToggleButton
                        key={option.id}
                        value={option.id}
                        aria-label={option.label}
                        style={{
                          background: gamePredictions[index] === option.id ? '#5333EC' : '#121212',
                          border: 'none',
                          color: '#DBDBDB'
                        }}
                      >
                        {option.label} ({option.symbol}): {option.odds}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {index !== dummyGames.length - 1 && <hr style={{ borderTop: '1px solid #D7D7D7', margin: '20px 0' }} />}
        </div>
      ))}
      <Pagination
        count={15}
        onChange={handlePaginationClick}
        style={{ marginTop: '20px', display: 'inline-block'}}
        color="primary"
        sx={{
          '& .MuiPaginationItem-root': {
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(45deg, #5333EC, #8A2BE2)', 
              border: '1px solid transparent',
            },
          },
          '& .MuiPaginationItem-page.Mui-selected': {
            background: '#5333EC', // Background color for selected pagination
            color: 'white', // Text color for selected pagination
            border: '1px solid transparent',
            '&:hover': {
              background: '#5333EC', // Background color for selected pagination on hover
              color: 'white', // Text color for selected pagination on hover
              border: '1px solid transparent',
            },
          },
        }}
      />
    </div>
  );
};

export default GamePredictionCard;
