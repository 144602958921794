import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const League = () => {
  const [value, setValue] = useState(0);
  const [leagues, setLeagues] = useState([]);
  const [eplStandings, setEPLStandings] = useState([]);
  const [laLigaStandings, setLaLigaStandings] = useState([]);

  useEffect(() => {
    // Here you can make your Axios request to fetch league data
    // Example:
    // axios.get('your-backend-api-url/epl-standings')
    //   .then(response => {
    //     setEPLStandings(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching EPL standings data:', error);
    //   });
    // axios.get('your-backend-api-url/laliga-standings')
    //   .then(response => {
    //     setLaLigaStandings(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching La Liga standings data:', error);
    //   });

    // dummy data
    const dummyLeagues = [
      'EPL', 'La Liga', 'Bundesliga', 'Serie A', 'Ligue 1', 'UEFA Champions League',
      'UEFA Europa League', 'Primeira Liga', 'Eredivisie', 'Russian Premier League', 'Brasileirão Série A', 'MLS'
    ];
    setLeagues(dummyLeagues);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Dummy data for EPL standings
  const dummyEPLStandings = [
    {
      position: 1,
      team: "Manchester City",
      matchesPlayed: 38,
      wins: 27,
      draws: 5,
      losses: 6,
      goalsFor: 83,
      goalsAgainst: 22,
      points: 86
    },
    {
      position: 2,
      team: "Liverpool",
      matchesPlayed: 38,
      wins: 21,
      draws: 11,
      losses: 6,
      goalsFor: 73,
      goalsAgainst: 44,
      points: 74
    },

  ];

  // Dummy data for La Liga standings
  const dummyLaLigaStandings = [
    {
      position: 1,
      team: "Real Madrid",
      matchesPlayed: 38,
      wins: 28,
      draws: 6,
      losses: 4,
      goalsFor: 80,
      goalsAgainst: 25,
      points: 90
    },
    {
      position: 2,
      team: "Barcelona",
      matchesPlayed: 38,
      wins: 26,
      draws: 7,
      losses: 5,
      goalsFor: 86,
      goalsAgainst: 38,
      points: 85
    },
  ];

  const renderStandingsTable = (standings) => (
    <TableContainer component={Paper} style={{ marginTop: '1em', backgroundColor: '#AD7DEA', border: '2px solid rgba(173,125,234,1)', backdropFilter: 'blur(10px)', color: '#AD7DEA' }}>
      <Table sx={{ minWidth: 650, fontFamily: 'Poppins' }} aria-label="League Standings">
        <TableHead>
          <TableRow>
            <TableCell align="center"><strong>Position</strong></TableCell>
            <TableCell align="center"><strong>Team</strong></TableCell>
            <TableCell align="center"><strong>Matches Played</strong></TableCell>
            <TableCell align="center"><strong>Wins</strong></TableCell>
            <TableCell align="center"><strong>Draws</strong></TableCell>
            <TableCell align="center"><strong>Losses</strong></TableCell>
            <TableCell align="center"><strong>Goals For</strong></TableCell>
            <TableCell align="center"><strong>Goals Against</strong></TableCell>
            <TableCell align="center"><strong>Points</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standings.map((team) => (
            <TableRow key={team.position}>
              <TableCell align="center">{team.position}</TableCell>
              <TableCell align="center">{team.team}</TableCell>
              <TableCell align="center">{team.matchesPlayed}</TableCell>
              <TableCell align="center">{team.wins}</TableCell>
              <TableCell align="center">{team.draws}</TableCell>
              <TableCell align="center">{team.losses}</TableCell>
              <TableCell align="center">{team.goalsFor}</TableCell>
              <TableCell align="center">{team.goalsAgainst}</TableCell>
              <TableCell align="center">{team.points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ maxWidth: '100%', bgcolor: '#040404', height: '100vh' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable League Standings"
        textColor="secondary"
        indicatorColor="secondary"
      >
        {leagues.map((league, index) => (
          <Tab key={index} label={league} style={{ color: '#F2F1F2' }} />
        ))}
      </Tabs>
      {value === 0 && renderStandingsTable(dummyEPLStandings)} {/* Render EPL standings when the first tab is active */}
      {value === 1 && renderStandingsTable(dummyLaLigaStandings)} {/* Render La Liga standings when the second tab is active */}
      {/* Add similar conditions for other leagues */}
    </Box>
  );
}

export default League;
